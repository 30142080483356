.ManagerAdmin {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  padding: env(--space-16);
  flex-direction: column;
}

.logoContainer {
  display: flex;
  align-content: flex-start;
}

.section {
  display: flex;
  gap: env(--space-20);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &[data-logo="true"] {
    justify-content: flex-start;
  }


  & .card {
    max-width: calc(env(--space-72) * 7);

    & .title {
      margin-top: env(--space-40);
      font-size: env(--space-72);
      line-height: 1.1;
    }
  }
}

.title,
.description {
  margin-bottom: env(--space-24);
}

.buttons {
  display: flex;
  flex-direction: column;
}

.button {
  margin-right: env(--space-12);
  margin-bottom: env(--space-12);
  padding: 0 3rem !important;
}


@media (min-width: env(--breakpoint-small)) {
  .ManagerAdmin {
    align-items: center;
    justify-content: center;
  }

  .section {
    flex-direction: row;
    max-width: calc(env(--space-144) * 7);
    gap: env(--space-40);
  }

  .card {
    max-width: calc(env(--space-72) * 7);
  }

  .title {
    margin-top: env(--space-40);
  }

  .title,
  .description {
    margin-bottom: env(--space-24);
  }

  .buttons {
    flex-direction: row;
  }


  .button {
    margin-right: env(--space-12);
  }
}
